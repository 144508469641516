.loader {
  position: fixed;
  z-index: 1100;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
}

.wheel {
  fill: rgb(253, 253, 253);
  font-size: 100px;
  width: 100px;
  height: 100px;
  border-radius: 6em;
  text-align: center;
  animation: rolling 1s linear infinite;
}

#rim {
  fill: rgb(255, 255, 255);
}

#tire {
  fill: rgb(55, 55, 55);
}

.road {
  position: absolute;
  margin-top: 100px;
  width: 100%;
  height: 10px;
  border-radius: 5em;
  background-color: rgb(55, 55, 55);
  animation: road-move 2s linear infinite;
}

@keyframes rolling {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes road-move {
  from {
    transform: translateX(-140%);
  }

  to {
    transform: translateX(100%);
  }
}
